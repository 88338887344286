// app/javascript/trix-editor-overrides.js
window.addEventListener("trix-file-accept", function(event) {
  event.preventDefault()
  alert("File attachment not supported!")
})

document.addEventListener("trix-attachment-add", function(event) {
  if (event.attachment.getURL().startsWith('blob:')) {
    event.attachment.remove()
  }
})

var Trix = require("trix");

/* what the newly created button does */
Trix.config.textAttributes.red = {
  style: { color: "red" },
  parser: function(element) {
    return element.style.color === "red"
  },
  inheritable: true
}

Trix.config.textAttributes.green = {
  style: { color: "green" },
  parser: function(element) {
    return element.style.color === "green"
  },
  inheritable: true
}


Trix.config.textAttributes.highlight = {
  style: { 'background-color': 'yellow' },
  parser: function(element) {
    return element.style.backgroundColor === "yellow"
  },
  inheritable: true
}

Trix.config.textAttributes.underline = {
  style: { textDecoration: "underline" },
  parser: function (element) {
    return element.style.textDecoration === "underline"
  },
  inheritable: true
}

/* insert the button visual in the default toolbar */
addEventListener("trix-initialize", function(event) {
  var buttonHTML = '<button type="button" class="red trix-button" data-trix-attribute="red">RED</button>'
  buttonHTML += '<button type="button" class="trix-button" style="color:green !important" data-trix-attribute="green">GREEN</button>'
  buttonHTML += '<button type="button" class="yellow trix-button" data-trix-attribute="highlight">Highlight</button>'
  event.target.toolbarElement.querySelector(".trix-button-group").insertAdjacentHTML("beforeend", buttonHTML)


  var underlineEl = document.createElement("button");
  underlineEl.setAttribute("type", "button");
  underlineEl.setAttribute("data-trix-attribute", "underline");
  underlineEl.setAttribute("data-trix-key", "u");
  underlineEl.setAttribute("tabindex", -1);
  underlineEl.setAttribute("title", "underline");
  underlineEl.classList.add("trix-button", "trix-button--icon-underline");
  underlineEl.innerHTML = "U";

  event.target.toolbarElement.querySelector(".trix-button-group").appendChild(underlineEl)
})
